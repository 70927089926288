/**********************************************************/
/* MB CORPO S TEXT >> Light: 300, Regular: 400, Bold: 700 */
/**********************************************************/
@font-face {
  font-display: fallback;
  font-family: "MB Corpo S Text";
  font-style: normal;
  font-weight: 300;
  src: url("./files/MBCorpoSText/MBCorpoSText-Light-Web.woff2") format("woff2"),
    url("./files/MBCorpoSText/MBCorpoSText-Light-Web.woff") format("woff"),
    url("./files/MBCorpoSText/MBCorpoSText-Light-Web.ttf") format("truetype");
}

@font-face {
  font-display: fallback;
  font-family: "MB Corpo S Text";
  font-style: normal;
  font-weight: 400;
  src: url("./files/MBCorpoSText/MBCorpoSText-Regular-Web.woff2") format("woff2"),
    url("./files/MBCorpoSText/MBCorpoSText-Regular-Web.woff") format("woff"),
    url("./files/MBCorpoSText/MBCorpoSText-Regular-Web.ttf") format("truetype");
}

@font-face {
  font-display: fallback;
  font-family: "MB Corpo S Text";
  font-style: normal;
  font-weight: 700;
  src: url("./files/MBCorpoSText/MBCorpoSText-Bold-Web.woff2") format("woff2"),
    url("./files/MBCorpoSText/MBCorpoSText-Bold-Web.woff") format("woff"),
    url("./files/MBCorpoSText/MBCorpoSText-Bold-Web.ttf") format("truetype");
}


/********************************************************************/
/* MB CORPO S TEXT CONDENSED >> Light: 300, Regular: 400, Bold: 700 */
/********************************************************************/
@font-face {
  font-display: fallback;
  font-family: "MB Corpo S Text Condensed";
  font-style: normal;
  font-weight: 300;
  src: url("./files/MBCorpoSTextCond/MBCorpoSText-CondLight-Web.woff2") format("woff2"),
    url("./files/MBCorpoSTextCond/MBCorpoSText-CondLight-Web.woff") format("woff"),
    url("./files/MBCorpoSTextCond/MBCorpoSText-CondLight-Web.ttf") format("truetype");
}

@font-face {
  font-display: fallback;
  font-family: "MB Corpo S Text Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("./files/MBCorpoSTextCond/MBCorpoSText-CondRegular-Web.woff2") format("woff2"),
    url("./files/MBCorpoSTextCond/MBCorpoSText-CondRegular-Web.woff") format("woff"),
    url("./files/MBCorpoSTextCond/MBCorpoSText-CondRegular-Web.ttf") format("truetype");
}

@font-face {
  font-display: fallback;
  font-family: "MB Corpo S Text Condensed";
  font-style: normal;
  font-weight: 700;
  src: url("./files/MBCorpoSTextCond/MBCorpoSText-CondBold-Web.woff2") format("woff2"),
    url("./files/MBCorpoSTextCond/MBCorpoSText-CondBold-Web.woff") format("woff"),
    url("./files/MBCorpoSTextCond/MBCorpoSText-CondBold-Web.ttf") format("truetype");
}

/***********************************************************/
/* MB CORPO S TITLE >> Light: 300, Regular: 400, Bold: 700 */
/***********************************************************/
@font-face {
  font-display: fallback;
  font-family: "MB Corpo S Title";
  font-style: normal;
  font-weight: 300;
  src: url("./files/MBCorpoSTitle/MBCorpoSTitle-Light-Web.woff2") format("woff2"),
    url("./files/MBCorpoSTitle/MBCorpoSTitle-Light-Web.woff") format("woff"),
    url("./files/MBCorpoSTitle/MBCorpoSTitle-Light-Web.ttf") format("truetype");
}

@font-face {
  font-display: fallback;
  font-family: "MB Corpo S Title";
  font-style: normal;
  font-weight: 400;
  src: url("./files/MBCorpoSTitle/MBCorpoSTitle-Regular-Web.woff2") format("woff2"),
    url("./files/MBCorpoSTitle/MBCorpoSTitle-Regular-Web.woff") format("woff"),
    url("./files/MBCorpoSTitle/MBCorpoSTitle-Regular-Web.ttf") format("truetype");
}

@font-face {
  font-display: fallback;
  font-family: "MB Corpo S Title";
  font-style: normal;
  font-weight: 700;
  src: url("./files/MBCorpoSTitle/MBCorpoSTitle-Bold-Web.woff2") format("woff2"),
    url("./files/MBCorpoSTitle/MBCorpoSTitle-Bold-Web.woff") format("woff"),
    url("./files/MBCorpoSTitle/MBCorpoSTitle-Bold-Web.ttf") format("truetype");
}
/*********************************************************************/
/* MB CORPO S TITLE CONDENSED >> Light: 300, Regular: 400, Bold: 700 */
/*********************************************************************/
@font-face {
  font-display: fallback;
  font-family: "MB Corpo S Title Condensed";
  font-style: normal;
  font-weight: 300;
  src: url("./files/MBCorpoSTitleCond/MBCorpoSTitle-CondLight-Web.woff2") format("woff2"),
    url("./files/MBCorpoSTitleCond/MBCorpoSTitle-CondLight-Web.woff") format("woff"),
    url("./files/MBCorpoSTitleCond/MBCorpoSTitle-CondLight-Web.ttf") format("truetype");
}

@font-face {
  font-display: fallback;
  font-family: "MB Corpo S Title Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("./files/MBCorpoSTitleCond/MBCorpoSTitle-CondRegular-Web.woff2") format("woff2"),
    url("./files/MBCorpoSTitleCond/MBCorpoSTitle-CondRegular-Web.woff") format("woff"),
    url("./files/MBCorpoSTitleCond/MBCorpoSTitle-CondRegular-Web.ttf") format("truetype");
}

@font-face {
  font-display: fallback;
  font-family: "MB Corpo S Title Condensed";
  font-style: normal;
  font-weight: 700;
  src: url("./files/MBCorpoSTitleCond/MBCorpoSTitle-CondBold-Web.woff2") format("woff2"),
    url("./files/MBCorpoSTitleCond/MBCorpoSTitle-CondBold-Web.woff") format("woff"),
    url("./files/MBCorpoSTitleCond/MBCorpoSTitle-CondBold-Web.ttf") format("truetype");
}