/*********************************************/
/* MB CORPO A TEXT CONDENSED >> Regular: 400 */
/*********************************************/
@font-face {
  font-display: fallback;
  font-family: "MB Corpo A Text Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("./files/MBCorpoATextCond/MBCorpoATextCond-Regular-Web.woff2") format("woff2"),
    url("./files/MBCorpoATextCond/MBCorpoATextCond-Regular-Web.woff") format("woff"),
    url("./files/MBCorpoATextCond/MBCorpoATextCond-Regular-Web.ttf") format("truetype");
}


/*********************************************/
/* MB CORPO A TITLE >> Regular: 400 */
/*********************************************/
@font-face {
  font-display: fallback;
  font-family: "MB Corpo A Title";
  font-style: normal;
  font-weight: 400;
  src: url("./files/MBCorpoATitle/MBCorpoATitle-Regular-Web.woff2") format("woff2"),
    url("./files/MBCorpoATitle/MBCorpoATitle-Regular-Web.woff") format("woff"),
    url("./files/MBCorpoATitle/MBCorpoATitle-Regular-Web.ttf") format("truetype");
}

/*********************************************/
/* MB CORPO A TITLE CONDENSED >> Regular: 400 */
/*********************************************/
@font-face {
  font-display: fallback;
  font-family: "MB Corpo A Title Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("./files/MBCorpoATitleCond/MBCorpoATitleCond-Regular-Web.woff2") format("woff2"),
    url("./files/MBCorpoATitleCond/MBCorpoATitleCond-Regular-Web.woff") format("woff"),
    url("./files/MBCorpoATitleCond/MBCorpoATitleCond-Regular-Web.ttf") format("truetype");
}

/*********************************************/
/* MB CORPO A SCREEN 1 CONDENSED >> Regular: 400 */
/*********************************************/
@font-face {
  font-display: fallback;
  font-family: "MB Corpo A Screen 1 Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("./files/MBCorpoAScreen1Cond/MBCorpoAScreen1Cond-Regular-Web.woff2") format("woff2"),
    url("./files/MBCorpoAScreen1Cond/MBCorpoAScreen1Cond-Regular-Web.woff") format("woff"),
    url("./files/MBCorpoAScreen1Cond/MBCorpoAScreen1Cond-Regular-Web.ttf") format("truetype");
}

/*********************************************/
/* MB CORPO A SCREEN 2 CONDENSED >> Regular: 400 */
/*********************************************/
@font-face {
  font-display: fallback;
  font-family: "MB Corpo A Screen 2 Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("./files/MBCorpoAScreen2Cond/MBCorpoAScreen2Cond-Regular-Web.woff2") format("woff2"),
    url("./files/MBCorpoAScreen2Cond/MBCorpoAScreen2Cond-Regular-Web.woff") format("woff"),
    url("./files/MBCorpoAScreen2Cond/MBCorpoAScreen2Cond-Regular-Web.ttf") format("truetype");
}