.release-card ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.release-card ul > li{
    width: auto;
}

